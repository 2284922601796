import React, {Component} from 'react';
import {Box, Flex} from '@rebass/grid';
import {Spinner, Dialog, Classes, Button} from "@blueprintjs/core";
import ReactResizeDetector from 'react-resize-detector';

import { connect } from 'react-redux';

import Icon from '../../../../components/icon';
import {bindActionCreators} from "redux";

import { toggleEditor, getDetails, deleteRecord, toggleAddressEditor, toggleNotificationsEditor, toggleCompanyEditor } from "../../../../store/contractors/actions";
import { toast } from "../../../../store/toaster/actions";
import moment from "moment";


class Details extends Component {

  state = {
    deleteDialog: false,
  }

  componentDidMount() {
    this.props.getDetails(this.props.match.params._id);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { record } = nextProps;
    if (record && (record.id !== parseInt(nextProps.match.params._id))) {
      nextProps.getDetails(nextProps.match.params._id);
    }
  }

  handleDelete() {
    this.props.deleteRecord(this.props.match.params._id, status => {
      if (status === 'saved'){

        this.setState({deleteDialog: false}, () => {
          this.props.toast({message: 'Contractor was successfully deleted', intent:'SUCCESS', icon: 'trash'});
          this.props.history.push('/contractors');
        });

      } else if (status === 'error') {
        this.setState({deleteDialog: false}, () => {
          this.props.toast({message: 'Server Error', intent: 'DANGER', icon: 'error'});
        })
      }
    });
  }

  render() {
    const { record, toggleEditor, toggleAddressEditor, toggleNotificationsEditor, toggleCompanyEditor } = this.props;
    if (record) {
      return (
        <React.Fragment>
        <Flex flexWrap={'wrap'} p={'16px'}>
          <Box width={['100%','100%','100%','100%','45%', '35%']} pr={['0','0','0','0','5px']}>
            <div className="panel">
              <div className="panel-head">
                <div className="tools">
                  <div className="icon" onClick={() => this.setState({deleteDialog: true})}>
                    <Icon icon="trash" color="#fff"/>
                  </div>
                  <div className="icon" onClick={() => toggleEditor(record)}>
                    <Icon icon="inclined-pencil" color="#fff"/>
                  </div>
                </div>
                <h2>{record.first_name} {record.last_name}</h2>
                <div className="data-item-container blue">
                  <div>Email</div>
                  <div>{record.email}</div>
                </div>
                <div className="data-item-container blue">
                  <div>Phone</div>
                  <div>{record.phone}</div>
                </div>
              </div>
              <div className="panel-body">
                <div className="data-item-container white">
                  <div>Crew Code</div>
                  <div>{record.crew_code && record.crew_code}</div>
                </div>
                <div className="data-item-container white">
                  <div>Date Of Birth</div>
                  <div>{moment(record.date_of_birth).isValid() ? moment(record.date_of_birth,['DD/MM/YYYY']).format('DD/MM/YYYY') : ''}</div>
                </div>
                <div className="data-item-container white">
                  <div>National Insurance Number</div>
                  <div>{record.national_insurance_number}</div>
                </div>
                <div className="data-item-container white">
                  <div>PPS Number</div>
                  <div>{record.pps_number}</div>
                </div>
                <div className="data-item-container white">
                  <div>Gender</div>
                  <div>{record.gender && record.gender.name && record.gender.name}</div>
                </div>
                <div className="data-item-container white">
                  <div>Agency</div>
                  <div>{record.agency && record.agency.name && record.agency.name}</div>
                </div>
                <div className="data-item-container white">
                  <div>Profession</div>
                  <div>{record.profession && record.profession.name && record.profession.name}</div>
                </div>
                <div className="data-item-container white">
                  <div>Position</div>
                  <div>{record.position && record.position.name && record.position.name}</div>
                </div>
              </div>
            </div>
          </Box>
          <Box width={['100%','100%','100%','100%','55%', '65%']} pl={['0','0','0','0','5px']}>
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }) =>
                <div className={width > 620 ? 'double' : 'single'}>
                  <div className="cols">
                    <div className="card">
                        <h5 className="card-title">Address
                          <div className="icon" onClick={() => toggleAddressEditor(record)}><Icon icon="inclined-pencil" color="#000"/>
                        </div></h5>
                        <div className="card-body">
                          {record.address_1 && <div>{record.address_1}</div>}
                          {record.address_2 && <div>{record.address_2}</div>}
                          {record.address_3 && <div>{record.address_3}</div>}
                          {record.address_4 && <div>{record.address_4}</div>}
                          {record.country && <div>{record.country}</div>}
                        </div>
                    </div>
                    <div className="card">
                      <h5 className="card-title">Portal Access</h5>
                      <div className="card-body right">

                        <table className="info-table">
                          <tbody>
                          <tr>
                            <td>Status</td>
                            <td>{record.status && record.status === 1 ? 'Active' : 'Inactive'}</td>
                          </tr>
                          <tr>
                            <td>Username</td>
                            <td>{record.username}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h5 className="card-title">Marital Status & Spouse Details</h5>
                    <div className="card-body">
                    </div>
                  </div>
                  <div className="cols">
                    <div className="card">
                      <h5 className="card-title">Notifications
                        <div className="icon" onClick={() => toggleNotificationsEditor(record)}><Icon icon="inclined-pencil" color="#000"/>
                        </div></h5>
                      <div className="card-body">
                        <table className="info-table">
                          <tbody>
                          <tr>
                            <td>Email - Payslip</td>
                            <td>{record.notifications_payslips === 1 ? 'Active' : record.notifications_payslips === 0 ? 'Inactive' : 'Not Set'}</td>
                          </tr>
                          <tr>
                            <td>Email - WorkingHours</td>
                            <td>{record.notifications_working_hours === 1 ? 'Active' : record.notifications_working_hours === 0 ? 'Inactive' : 'Not Set'}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card">
                      <h5 className="card-title">Fees</h5>
                      <div className="card-body right">

                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <h5 className="card-title">Company Details<div className="icon" onClick={() => toggleCompanyEditor(record)}><Icon icon="inclined-pencil" color="#000"/>
                    </div></h5>
                    <div className="card-body cols">
                      <table className="info-table gray">
                        <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{record.company && record.company.name && record.company.name}</td>
                        </tr>
                        <tr>
                          <td>Proprietary Director</td>
                          <td>{record.proprietary_director && record.proprietary_director === 1 ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                          <td>Share Holding</td>
                          <td>{record.share_holding && record.share_holding ? `${record.share_holding}%` : '0%'}</td>
                        </tr>
                        </tbody>
                      </table>
                      <table className="info-table">
                        <tbody>
                        <tr>
                          <td>Date of appointment</td>
                          <td>{record.date_of_appointment && record.date_of_appointment}</td>
                        </tr>
                        <tr>
                          <td>Date of resignation</td>
                          <td>{record.date_of_resignation && record.date_of_resignation}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              }
            </ReactResizeDetector>
          </Box>
        </Flex>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="Contractor Delete"
            isOpen={this.state.deleteDialog}
          >
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </React.Fragment>
      );
    } else {
      return (
        <Spinner className="mt50" />
      )
    }
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    toggleEditor,
    toggleAddressEditor,
    toggleNotificationsEditor,
    toggleCompanyEditor,
    getDetails,
    deleteRecord,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    record: state.contractors.selectedRecord,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Details));
