import React, {Component} from 'react';
import styled from 'styled-components';
import { darken } from 'polished';


const StyledCheckbox = styled.div`
  position: relative;
  display: flex;
  border-radius: 4px;
  background-color: ${props => props.color};
  border-color: ${props => darken(0.2, props.color)};
  padding: 10px;
  color: #fff;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  
  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    
    .check-mark {
      height: 10px;
      width: 5px;
      margin-bottom: 1px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: ${props => props.isActive ? 0.9 : 0}; 
    }
  }
  
  .count {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10px;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${props => darken(0.2, props.color)};
  }
  

`


class ExpenseTypeFilter extends Component {
  state = {
    checked: false,
  }

  onChange() {
    this.setState({checked: !this.state.checked},
      ()=> this.props.onChange(this.state.checked));
  }

  render() {
    const {total, label} = this.props;

    return (
      <StyledCheckbox isActive={this.state.checked} onClick={() => this.onChange()} color={this.props.color}>
        {label}
        <div className="checkbox"><div className="check-mark"></div></div>
        <div className="count">{total}</div>
      </StyledCheckbox>
    );
  }
}

export default ExpenseTypeFilter;
