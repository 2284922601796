import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getBankAccounts, toggleBankAccountEditor} from "../../../../store/contractors/actions";
import {connect} from "react-redux";
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from "styled-components";
import Fuse from "fuse.js";

import 'react-virtualized/styles.css';
import {Button, InputGroup, NonIdealState, Spinner} from "@blueprintjs/core";
import SlidingPanel from "../../../../components/slidingpanel";
import BankAccountsEditor from "./BankAccountsEditor";

import {CSVLink} from "react-csv";
import Icon from "../../../../components/icon";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'name', weight: 0.4},
    {name: 'iban', weight: 0.2},
    {name: 'account_number', weight: 0.2},
    {name: 'sort_code', weight: 0.2},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Name', key: 'name' },
  { label: 'IBAN', key: 'iban' },
  { label: 'Account Number', key: 'account_number' },
  { label: 'Sort code', key: 'sort_code' },
];


class BankAccounts extends Component {
  state = {
    search: '',
    record: parseInt(this.props.match.params._id)
  }

  componentDidMount() {
    this.props.getBankAccounts(this.props.match.params._id, r => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.record !== parseInt(nextProps.match.params._id)){
      this.setState({
        record: parseInt(nextProps.match.params._id)
      }, () => {
          this.props.getBankAccounts(this.props.match.params._id, r => {});
        });
    }
  }

  render() {
    const { bankAccounts, bankAccountsLoading, toggleBankAccountEditor, isBankAccountEditorActive } = this.props;
    const fuse = new Fuse(bankAccounts, FUSE_OPTIONS);
    const records = this.state.search.length ? fuse.search(this.state.search) : bankAccounts;

    return (
      <React.Fragment>
        {bankAccountsLoading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="tools">
              <Button large onClick={() => toggleBankAccountEditor({})} icon="add" intent="success" text="Add New" />

              <div className="flex">
                {records.length > 0 &&
                <CSVLink
                  headers={EXCEL_HEADERS}
                  data={records}
                  filename={"bank_accounts.csv"}
                  className="mr5"
                  target="_blank"
                >
                  <Icon icon="csv" size={38}/>
                </CSVLink>
                }
              <InputGroup
                leftIcon="search"
                onChange={(e) => {
                  this.setState({
                    search: e.currentTarget.value
                  })
                }}
                name="vat_number"
                id="vat_number"
                large
                placeholder="Search"
              />
              </div>
            </div>
            {records.length > 0 ? (
              <StyledList>
              <AutoSizer>
                {({width, height}) => (
                  <Table
                    ref="Table"
                    headerHeight={30}
                    noRowsRenderer={this._noRowsRenderer}
                    overscanRowCount={10}
                    rowClassName={this._rowClassName}
                    rowHeight={30}
                    rowCount={records.length}
                    rowGetter={({ index }) => records[index]}
                    height={height}
                    onRowClick={(event) => toggleBankAccountEditor(event.rowData)}
                    width={width}>
                    <Column
                      label="Name"
                      dataKey="name"
                      width={width * 0.5}
                    />
                    <Column
                      width={width * 0.5}
                      disableSort
                      label="Iban"
                      dataKey="iban"
                      flexGrow={1}
                    />
                  </Table>
                )}
              </AutoSizer>
            </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No bank accounts found for this contractors"
              />
            )}
          </React.Fragment>
        )}
        <SlidingPanel width="520px" isOpen={isBankAccountEditorActive} title="Bank Account" onRequestClose={() => toggleBankAccountEditor()}>
          <BankAccountsEditor/>
        </SlidingPanel>

      </React.Fragment>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getBankAccounts,
    toggleBankAccountEditor,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    bankAccountsLoading: state.contractors.bankAccountsLoading,
    bankAccounts: state.contractors.bankAccounts,
    isBankAccountEditorActive: state.contractors.isBankAccountEditorActive,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(BankAccounts));
