import React, {Component} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import Switch from 'react-switch';
import moment from "moment";
import {FormGroup, InputGroup, Intent, Button, Checkbox} from '@blueprintjs/core';
import Select from 'react-select';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Collapse } from 'react-collapse';
import axios from 'axios';

const StyledExpensesForm = styled.form`
  height: 100%;
`;

const statuses = {
  status_1: 'Awaiting Approval',
  status_2: 'Approved',
  status_3: 'Rejected',
  status_4: 'Deleted',
  status_5: 'Paid',
};

class TravelExpenseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {

      subsistenceClaim: props.record && props.record.subsistenceClaim ? true : false,
      subsistenceRate: null,
      subsistenceRateValue: 0,
      startDate: props.record && props.record.startDate ? props.record.startDate : null,
      startDateInvalid: '',
      endDate: props.record && props.record.endDate ? props.record.endDate : null,
      endDateInvalid: '',
      durationDays: 0,
      durationHours: 0,

      location: props.record && props.record.location ? props.record.location : null,
      locationInvalid: '',
      additionalAccommodation: props.record && props.record.additionalAccommodation ? props.record.additionalAccommodation : '',
      carUsed: props.record && props.record.carUsed ? true : false,
      tripBegin: props.record && props.record.tripBegin ? props.record.tripBegin : '',
      tripBeginInvalid: '',
      tripEnd: props.record && props.record.tripEnd ? props.record.tripEnd : '',
      tripEndInvalid: '',


      engineSizeInvalid: '',
      mileageToDate: 0,
      distanceTraveled: props.record && props.record.distanceTraveled ? props.record.distanceTraveled : '',
      distanceTraveledInvalid: '',
      carBegin: props.record && props.record.carBegin ? props.record.carBegin : '',
      carBeginInvalid: '',
      carEnd: props.record && props.record.carEnd ? props.record.carEnd : '',
      carEndInvalid: '',
      carModel: props.record && props.record.carModel ? props.record.carModel : '',
      carModelInvalid: '',

      subsistenceBreakdown: true,
      mileageBreakdown: false,
      currency: props.record && props.record.currency ? props.record.currency : null,




      statusName: '',
      recordId: null,
      loading: true,

      locations: [],
      engineSizes: [],

      sent: false,
      statusId: null,
      deleteDialog: false,
      rejectDialog: false,
      rejection_reason: '',
      rejectionSent: false,
      eurValue: 0,
      fields: {
        incurred: null,
        start_date: null,
        end_date: null,
        subsistenceClaim: false,
        location: null,
        additionalAccommodation: null,
        start_location: null,
        tripEnd: null,
        carUsed: false,
        engineSize: null,
        distanceTraveled: 0,
        carBegin: null,
        carEnd: null,
        carModel: null,
        userId: null,
      },
      errors: {},
      generalError: '',
    };
  }

  componentDidMount() {
    const { expense, userId } = this.props;
    let expenseId = expense && expense.id ? expense.id : 0;
    if (this.props.match.params._expense_id > 0){
      expenseId = this.props.match.params._expense_id;
    }
    axios.get(`contractors/${userId}/expenses/travel/${expenseId}`)
      .then(result => {
        const { record, locations, engineSizes } = result.data;
        const formattedLocations = this.formatLocations(locations);
        const formattedEngineSizes = this.formatEngineSizes(engineSizes);
        const startDate = record && record.start_time ? moment(record.start_time).toDate() : null;
        const endDate = record && record.end_time ? moment(record.end_time).toDate() : null;
        const location = this.getLocation(record, formattedLocations);
        const engineSize = record && record.engine_size_id && record.engine_size_id > 0 ? record.engine_size_id : 0;
        const selectedEngine = engineSize ? formattedEngineSizes.find(el => el.value === engineSize) : null;

        this.setState({
          loading: false,
          statusName: record && record.status_id ? statuses[`status_${record.status_id}`] : '',
          locations: formattedLocations,
          engineSizes: formattedEngineSizes,

          startDate,
          endDate,
          tripBegin: record && record.start_location ? record.start_location : '',
          tripEnd: record && record.end_location ? record.end_location : '',
          subsistenceClaim: record && record.location ? true : false,
          location,
          selectedEngine,
          currency: record && record.currency ? record.currency : 'EUR',
          carUsed: record && record.use_car && record.use_car > 0,
          fields: {
            ...record,
            location_id: record && record.location_id ? record.location_id : null,
            location: record && record.location ? record.location : '',
            start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            start_location: record && record.start_location ? record.start_location : '',
            end_location: record && record.end_location ? record.end_location : '',
          },
          // mileageToDate: result.data.data.mileage_to_date,
        }, () => {
          if (record) {
            this.getDuration();
            this.recalculate()
          }
        });

      })
      .catch((error)=>{
        console.log(error);
      });
  }

  getLocation(record, locations) {
    let l = null;
    if (record) {
      locations.map(location => {
        if (record.location === location.label) {
          l = location;
        }
        return location;
      });
    }
    return l;
  }

  formatLocations(locations) {
    const formattedLocations = [];
    locations.map(location => {
      formattedLocations.push({
        label: `${location.country} (${location.name})`,
        value: location.id,
        over5HoursRate: location.hours_5,
        over10HoursRate: location.hours_10,
        overnightRate: location.overnight,
        additionalAccommodation: location.additional_accommodation,
        additionalOvernightSubsistence: location.additionalOvernightSubsistence,
        additionalOvernightSubsistenceCurrency: location.additionalOvernightSubsistenceCurrency,
        additionalOvernightSubsistenceValue: location.currency_2_id,


      });
      return location;
    });
    return formattedLocations;
  }

  formatEngineSizes(engineSizes) {
    const formattedEngineSizes = [];
    engineSizes.map(engineSize => {
      const eSize = engineSize;
      eSize.label = engineSize.name;
      eSize.value = engineSize.id;
      formattedEngineSizes.push(eSize);
      return engineSize;
    });
    return formattedEngineSizes;
  }

  isClaim() {
    if (this.state.sent) {
      if (!this.state.subsistenceClaim && !this.state.carUsed) {
        this.setState({
          generalError: 'Select At least one option bellow'
        });
      } else {
        this.setState({
          generalError: ''
        });
      }
    }
  }

  onSubsistenceClaimChanged(checked) {
    this.setState({
      subsistenceClaim: checked,
    }, () => this.isClaim());
  }

  onCarUsedChanged(checked) {
    this.setState({
      carUsed: checked,
    }, () => this.isClaim());
  }

  changeLocation(location) {
    console.log(location);
    this.setState({
      location,
      locationInvalid: ''
    }, () => this.getDuration());
  }

  recalculate() {
    const { durationDays, durationHours, location } = this.state;

    console.log(durationDays);

    if (location && (durationHours || durationDays )) {
      let subsistenceValue = 0;
      if (durationDays) {
        subsistenceValue += durationDays * location.overnightRate;
      }

      let subsistenceRate = null;
      let subsistenceRateValue = 0;
      if ( durationHours > 10 ) {
        subsistenceValue += location.over10HoursRate;
        subsistenceRate = 'between 10 and 24 hours';
        subsistenceRateValue = location.over10HoursRate;
      } else if (durationHours > 5) {
        subsistenceValue += location.over5HoursRate;
        subsistenceRate = 'between 5 and 10 hours';
        subsistenceRateValue = location.over5HoursRate;
      }

      this.setState({
        subsistenceRate,
        subsistenceRateValue
      });
    }
  }

  getDuration() {
    // calculates duration between two dates
    // returns number of days and hours

    // get total seconds between the times
    const startDate = this.state.startDate ? this.state.startDate.getTime() : null;
    const endDate = this.state.endDate ? this.state.endDate.getTime() : null;

    if (startDate && endDate) {
      // get total seconds between the times
      let delta = Math.abs(endDate - startDate) / 1000;

      // calculate (and subtract) whole days
      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      const hours = Math.floor(delta / 3600) % 24;

      this.setState({
        durationDays: days,
        durationHours: hours,
      }, () => this.recalculate());
    }
  }

  onStartChanged(e) {
    try {
      const startDate = e;
      this.setState({
        startDateInvalid: '',
        startDate
      }, () => this.getDuration());
    } catch ( exception ) {
      this.setState({
        startDateInvalid: 'This Field is required'
      });
      return;
    }
  }

  onEndChanged(e) {
    try {
      const endDate = e;
      this.setState({
        endDateInvalid: '',
        endDate
      }, () => this.getDuration());
    } catch ( exception ) {
      this.setState({
        endDateInvalid: 'This Field is required'
      });
      return;
    }
  }

  onFocusChanged(fieldName) {
    if (this.props.onFocusChanged) {
      this.props.onFocusChanged(fieldName);
    }
  }

  onAdditionalAccommodationChanged(event) {
    const value = event.target.value;
    if (isNaN(value)) {
      this.setState({
        errors: {
          ...this.state.errors,
          additionalAccommodationInvalid: 'Only numbers allowed',
        }

      }, () => this.recalculate());
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          additionalAccommodationInvalid: null,
        }
      }, () => this.recalculate());
    }
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        fields: {...this.state.fields, [name]: value},
        errors: {...this.state.errors, [name]: 'This Field is required.'}
      });
    } else {
      this.setState({
        fields: {...this.state.fields, [name]: value},
        errors: {...this.state.errors, [name]: undefined}
      });
    }
  }

  changeEngineSize(engineSize) {
    console.log(engineSize);
    this.setState({
      selectedEngine: engineSize,
      engineSizeInvalid: ''
    }, () => this.calculateMileage());
  }

  calculateMileage() {
    if (this.state.engineSize && this.state.distanceTraveled.length) {

      const mileageToDate = this.state.mileageToDate;
      const distance = parseInt(this.state.distanceTraveled);
      const total = mileageToDate + distance;
      let deduction = 0;
      let finalValue = 0;

      for (let i = 1; i < 5; i++) {
        const limit = this.state.engineSize[`limit${i}`];
        const rate = this.state.engineSize[`rate${i}`];

        if (mileageToDate < limit) {
          if (total < limit) {
            finalValue += (distance - deduction) * rate;
            deduction += (distance - deduction);
          } else {
            const kmUsed = limit - mileageToDate - deduction;
            if (kmUsed < 0) {
              finalValue += (kmUsed * rate);
              deduction += (limit - deduction);
            } else {
              finalValue += (kmUsed * rate);
              deduction += kmUsed;
            }
          }
        }
      }
    }
  }

  onDistanceChanged(event) {
    const value = event.target.value;
    if (value.length === 0) {
      this.setState({
        distanceTraveled: '',
        distanceTraveledInvalid: 'This field is required',
      }, () => this.calculateMileage());
    } else if (isNaN(value)) {
      this.setState({
        distanceTraveledInvalid: 'Only numbers allowed',
      }, () => this.calculateMileage());
    } else if (value === '0') {
      this.setState({
        distanceTraveledInvalid: 'Zero is not allowed',
      }, () => this.calculateMileage());
    } else {
      this.setState({
        distanceTraveled: value,
        distanceTraveledInvalid: '',
      }, () => this.calculateMileage());
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.setState({
      sent: true,
    }, () => this.handleSubmit());
  }

  handleSubmit() {
    const { history } = this.props;
    let hasErrors = false;

    const errors = {
      startTime: null,
      endTime: null,
      description: null,
      currency: null,
      value: null,
      files: null,
      agree1: null,
      agree2: null,
    };


    if (moment(this.state.fields.start_time).isValid()) {
      errors.startTime = null;
    } else {
      errors.startTime = 'This field is required.'
      hasErrors = true;
    }

    if (moment(this.state.fields.end_time).isValid()) {
      errors.endTime = null;
    } else {
      errors.endTime = 'This field is required.'
      hasErrors = true;
    }

    if ( !this.state.subsistenceClaim && !this.state.carUsed ) {
      errors.generalError = 'Select At least one option bellow'
      hasErrors = true;
    } else {
      errors.generalError = null;
    }

    /*
    if ( this.state.subsistenceClaim ) {
      if (!this.state.location) {
        this.setState({
          locationInvalid: 'This field is required.'
        });
        hasErrors = true;
      }

      if (this.state.tripBegin.length === 0) {
        this.setState({
          tripBeginInvalid: 'This field is required.'
        });
        hasErrors = true;
      }

      if (!this.state.tripEnd.length) {
        this.setState({
          tripEndInvalid: 'This field is required.'
        });
        hasErrors = true;
      }
    }

    if ( this.state.carUsed ) {
      if (!this.state.engineSize) {
        hasErrors = true;
        this.setState({ engineSizeInvalid: 'This field is required.' });
      }
      if (this.state.distanceTraveled === '' && this.state.distanceTraveled <= 0) {
        hasErrors = true;
        this.setState({ distanceTraveledInvalid: 'This field is required.' });
      }
      if (!this.state.carBegin.length ) {
        hasErrors = true;
        this.setState({ carBeginInvalid: 'This field is required.' });
      }
      if (!this.state.carEnd.length ) {
        hasErrors = true;
        this.setState({ carEndInvalid: 'This field is required.' });
      }
      if (!this.state.carModel.length ) {
        hasErrors = true;
        this.setState({ carModelInvalid: 'This field is required.' });
      }
    }
    */

    console.log()

    if (hasErrors) {
      return;
    }

    return;


    const record = {
      incurred: this.state.startDate,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      subsistenceClaim: this.state.subsistenceClaim,
      location: this.state.location,
      additionalAccommodation: this.state.additionalAccommodation.length ? this.state.additionalAccommodation : null,
      tripBegin: this.state.tripBegin.length ? this.state.tripBegin : null,
      tripEnd: this.state.tripEnd.length ? this.state.tripEnd : null,
      carUsed: this.state.carUsed,
      engineSize: this.state.engineSize,
      distanceTraveled: this.state.distanceTraveled && this.state.distanceTraveled > 0 ? parseInt(this.state.distanceTraveled, 10) : 0,
      carBegin: this.state.carBegin.length ? this.state.carBegin : null,
      carEnd: this.state.carEnd.length ? this.state.carEnd : null,
      carModel: this.state.carModel.length ? this.state.carModel : null,
      userId: this.props.userId ? this.props.userId : null,
    };

    const existingRecord = this.props.record && this.props.record._id;
    const methodToCall = existingRecord ? 'expenses.updateTravel' : 'expenses.insertTravel';
    if (existingRecord) {record.id = existingRecord;}

    axios.post('/expenses/travel/add', record).then(result => {
      console.log(result);
    }).catch(error => {
      console.log(error.result);
    });

    /*
    Meteor.call(methodToCall, record, (error, recordId) => {
      if (error) {
        Bert.alert(error.reason, 'danger');
      } else {
        const confirmation = existingRecord ? 'Expense updated!' : 'Expense added!';
        if (this.props.userId){

        } else {
          if (this.props.closePanel) {
            this.props.closePanel();
          }else{
            history.push('/expenses');
          }
        }
      }
    });
    */
  }

  render() {
    const overNightRate = this.state.location && this.state.location.overnightRate ? this.state.location.overnightRate : 0;
    const overNightTotal = overNightRate * this.state.durationDays;
    const additionalOvernightSubsistence = this.state.location && this.state.location.additionalOvernightSubsistence ? this.state.location.additionalOvernightSubsistenceValue * this.state.durationDays : 0;
    const additionalAccommodation = (this.state.location && this.state.location.additionalAccommodation && this.state.additionalAccommodation.length) ? parseFloat(this.state.additionalAccommodation) : 0;
    const subsistenceTotal = "€" + (overNightTotal + this.state.subsistenceRateValue + additionalOvernightSubsistence + additionalAccommodation);

    const {loading, statusName, statusId, serverOperation, recordId} = this.state;

    let canEdit = (!recordId || parseInt(statusId) === 1) ? true : false;


    return (
      <React.Fragment>
        <StyledExpensesForm className="content" noValidate onSubmit={ event => this.submitForm(event)}>
          <Scrollbar noDefaultStyles>
            <div className="content-wrapper">
              <Flex flexWrap={'wrap'}>
                <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                  <FormGroup
                    style={{width: '100%'}}
                    intent={this.state.errors.startTime ? Intent.DANGER : Intent.SUCCESS}
                    label="Travel start date and time"
                    labelFor="startDate"
                    labelInfo="*"
                    helperText={this.state.errors.startTime && this.state.errors.startTime}
                  >
                    <DateInput
                      inputProps={{style: {width: '100%'}, className: 'bp3-large'}}
                      id="startDate"
                      formatDate={date => moment(date).locale('ie').format('DD/MM/YYYY HH:mm')}
                      parseDate={str => moment(str, 'DD/MM/YYYY HH:mm').locale('ie').toDate()}
                      onChange={(dt) => this.onStartChanged(dt)}
                      placeholder={"DD/MM/YYYY HH:MM"}
                      value={this.state.startDate}
                      timePrecision={TimePrecision.MINUTE}
                      showActionsBar
                      intent={this.state.errors.startDate ? Intent.DANGER : Intent.NONE}
                    />
                  </FormGroup>
                </Box>
                <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                  <FormGroup
                    intent={this.state.errors.endTime ? Intent.DANGER : Intent.SUCCESS}
                    label="Travel end date and time"
                    labelFor="endDate"
                    labelInfo="*"
                    helperText={this.state.errors.endTime && this.state.errors.endTime}
                  >
                    <DateInput
                      inputProps={{style: {width: '100%'}, className: 'bp3-large'}}
                      id="endDate"
                      className="bp3-large"
                      formatDate={date => moment(date).locale('ie').format('DD/MM/YYYY HH:mm')}
                      parseDate={str => moment(str, 'DD/MM/YYYY HH:mm').locale('ie').toDate()}
                      onChange={(dt) => this.onEndChanged(dt)}
                      placeholder={"DD/MM/YYYY HH:MM"}
                      value={this.state.endDate}
                      timePrecision={TimePrecision.MINUTE}
                      showActionsBar
                      intent={this.state.errors.endTime ? Intent.DANGER : Intent.NONE}
                    />
                  </FormGroup>
                </Box>
              </Flex>
              {this.state.generalError.length ?
                <div className="row">
                  <div className="general-error">{this.state.generalError}</div></div> : ''}
              <Flex>
                <Box width={'100%'} pl={'0'}>
                  <div className="card" id="subsitence-claim-panel"
                       onToggle={(checked) => this.onSubsistenceClaimChanged(checked)}>
                    <div className="card-title" onClick={() => {console.log('click');}}>
                      Subsistence claim
                      <Switch
                        onColor="#5db8f1"
                        offColor="#2c3c59"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        width={48}
                        height={24}
                        onChange={(checked) => this.onSubsistenceClaimChanged(checked)}
                        checked={this.state.subsistenceClaim}
                        id="normal-switch"
                      />
                    </div>
                    <Collapse isOpened={this.state.subsistenceClaim}>
                      <div className="card-body">
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.locationInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Location"
                              labelFor="location"
                              labelInfo="*"
                              helperText={this.state.locationInvalid.length ? this.state.locationInvalid : ''}
                            >
                              <Select
                                name="location"
                                multi={false}
                                options={this.state.locations}
                                onChange={option => this.changeLocation(option)}
                                defaultValue={this.state.location}
                                value={this.state.location}
                                onFocus={() => this.onFocusChanged('location')}
                                onBlur={() => this.onFocusChanged(null)}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                            <FormGroup
                              intent={this.state.errors.startDate ? Intent.DANGER : Intent.SUCCESS}
                              label="Additional Accommodation"
                              labelFor="additionalAccommodation"
                              helperText={this.state.errors.startDate && this.state.errors.startDate}
                              disabled={(this.state.location &&
                                !this.state.location.additionalAccommodation) ||
                              !this.state.location
                              }
                            >
                              <InputGroup
                                disabled={(this.state.location &&
                                  !this.state.location.additionalAccommodation) ||
                                !this.state.location
                                }
                                large
                                value={this.state.additionalAccommodation}
                                onChange={(event) => this.onAdditionalAccommodationChanged(event)}
                                onFocus={() => this.onFocusChanged('additional-accommodation')}
                                onBlur={() => this.onFocusChanged(null)}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.tripBeginInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Where did your trip begin?"
                              labelFor="tripBegin"
                              labelInfo="*"
                              helperText={this.state.tripBeginInvalid.length ? this.state.tripBeginInvalid : ''}
                            >
                              <InputGroup
                                name="tripBegin"
                                id="tripBegin"
                                large
                                onChange={(event) => this.handleUserInput(event)}
                                onFocus={() => this.onFocusChanged('trip-begin')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.tripBegin}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                            <FormGroup
                              intent={this.state.tripEndInvalid.length  ? Intent.DANGER : Intent.SUCCESS}
                              label="Where did your trip end?"
                              labelFor="tripEnd"
                              labelInfo="*"
                              helperText={this.state.tripEndInvalid.length ? this.state.tripEndInvalid : ''}
                            >
                              <InputGroup
                                name="tripEnd"
                                id="tripEnd"
                                large
                                onChange={(event) => this.handleUserInput(event)}
                                onFocus={() => this.onFocusChanged('trip-end')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.tripEnd}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                      </div>
                      { this.state.location ?
                        <div className="card" id="subsitence-breakdown-panel"
                             onToggle={(checked) => this.onSubsistenceClaimChanged(checked)}>
                          <div className="card-title" onClick={() => {
                            console.log('click');
                          }}>
                            Subsistence Calculation Breakdown
                            <Switch
                              onColor="#5db8f1"
                              offColor="#2c3c59"
                              checkedIcon={false}
                              uncheckedIcon={false}
                              width={32}
                              height={18}
                              onChange={(checked) => this.setState({
                                subsistenceBreakdown: checked
                              })}
                              checked={this.state.subsistenceBreakdown}
                              id="normal-switch"
                            />
                          </div>
                          <Collapse isOpened={this.state.subsistenceBreakdown}>

                            <Flex pl={10} pr={10}>
                              <Box width={['100%','45%','45%']}><b>Duration Away From Base</b>
                              </Box>
                              <Box width={['100%','55%','55%']}>
                                {this.state.durationDays} Days&nbsp;and {this.state.durationHours} Hours
                              </Box>
                            </Flex>
                            <Flex pl={10} pr={10}>
                              <Box width={['100%','45%','45%']}><b>Overnight Value</b></Box>
                              <Box width={['100%','55%','55%']}>
                                {this.state.location.overnightRate}{this.state.currency && this.state.currency.code} x &nbsp;
                                {this.state.durationDays} {this.state.durationDays === 1 ? 'Day' : 'Days'} =&nbsp;
                                {(this.state.location.overnightRate * this.state.durationDays).toFixed(2)} {this.state.currency && this.state.currency.code}
                              </Box>
                            </Flex>
                            {this.state.subsistenceRateValue > 0 ? <Flex pl={10} pr={10}>
                              <Box width={1/2}><b>1 x {this.state.subsistenceRate} rate</b>
                              </Box>
                              <Box>€{this.state.subsistenceRateValue}</Box>
                            </Flex> : ''}
                            {this.state.location.additionalOvernightSubsistence ? <Flex pl={10} pr={10}>
                              <Box width={['100%','45%','45%']}>
                                <b>Additional Overnight Subsistence</b>
                              </Box>
                              <Box width={['100%','55%','55%']}>
                                €{this.state.location.additionalOvernightSubsistenceValue} x&nbsp;
                                {this.state.durationDays} {this.state.durationDays === 1 ? 'Day' : 'Days'} =&nbsp;
                                €{(this.state.location.additionalOvernightSubsistenceValue * this.state.durationDays).toFixed(2)}
                              </Box>
                            </Flex> : ''}
                            {this.state.location.additionalAccommodation && this.state.additionalAccommodation.length && this.state.additionalAccommodation > 0 ?
                              <Flex pl={10} pr={10}>
                                <Box width={['100%','45%','45%']}>
                                  <b>Additional Subsistence</b>
                                </Box>
                                <Box width={['100%','55%','55%']}>
                                  €{this.state.additionalAccommodation}
                                </Box>
                              </Flex> : ''}
                            <Flex pl={10} pr={10}>
                              <Box width={['100%','45%','45%']}>
                                <b>Total</b>
                              </Box>
                              <Box width={['100%','55%','55%']}>
                                {subsistenceTotal}
                              </Box>
                            </Flex>
                          </Collapse>
                        </div>
                        : '' }

                    </Collapse>
                  </div>
                </Box>
              </Flex>
              <Flex>
                <Box width={'100%'}>
                  <div className="card"
                       onToggle={ (checked) => this.onCarUsedChanged(checked) }
                  >
                    <div className="card-heading">
                      <div className="card-title" onClick={() => {console.log('click');}}>
                        <div>Private car used for this business</div>
                        <Switch
                          onColor="#5db8f1"
                          offColor="#2c3c59"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          width={48}
                          height={24}
                          onChange={ (checked) => this.onCarUsedChanged(checked) }
                          checked={this.state.carUsed}
                          id="normal-switch"
                        />
                      </div>
                    </div>
                    <Collapse isOpened={this.state.carUsed}>
                      <div className="card-body">
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.engineSizeInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Engine Size"
                              labelFor="engineSize"
                              labelInfo="*"
                              helperText={this.state.engineSizeInvalid.length ? this.state.engineSizeInvalid : ''}
                            >
                              <Select
                                name="location"
                                multi={false}
                                options={this.state.engineSizes}
                                onChange={option => this.changeEngineSize(option)}
                                value={this.state.selectedEngine}
                                onFocus={() => this.onFocusChanged('engine-size')}
                                onBlur={() => this.onFocusChanged(null)}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                            <FormGroup
                              intent={this.state.errors.distanceTraveled ? Intent.DANGER : Intent.SUCCESS}
                              label="Distance traveled(km)"
                              labelFor="distanceTraveled"
                              labelInfo="*"
                              helperText={this.state.errors.distanceTraveled ? this.state.errors.distanceTraveled : ''}
                            >
                              <InputGroup
                                name="motor_distance"
                                id="distanceTraveled"
                                large
                                onChange={(event) => this.onDistanceChanged(event)}
                                onFocus={() => this.onFocusChanged('distance')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.motor_distance}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carBeginInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Where the car journey begin?"
                              labelFor="carBegin"
                              labelInfo="*"
                              helperText={this.state.carBeginInvalid.length ? this.state.carBeginInvalid : ''}
                            >
                              <InputGroup
                                name="motor_start_location"
                                id="carBegin"
                                large
                                onChange={(event) => this.handleUserInput(event)}
                                onFocus={() => this.onFocusChanged('car-begin')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.motor_start_location}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carEndInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Where the car journey end?"
                              labelFor="carEnd"
                              labelInfo="*"
                              helperText={this.state.carEndInvalid.length ? this.state.carEndInvalid : ''}
                            >
                              <InputGroup
                                name="motor_end_location"
                                id="carEnd"
                                large
                                onChange={(event) => this.handleUserInput(event)}
                                onFocus={() => this.onFocusChanged('car-end')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.motor_end_location}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carModelInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Round Trip?"
                              labelFor="carModel"
                              helperText={this.state.carModelInvalid.length ? this.state.carModelInvalid : ''}
                            >
                              <Checkbox
                                name="round_trip"
                                id="round_trip"
                                large

                                onChange={(event) => this.handleUserInput(event)}
                                onFocus={() => this.onFocusChanged('car-model')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.car_make_model}
                              />
                              <InputGroup
                                name="round_trip_location"
                                id="round_trip_location"
                                placeholder="First Stop"
                                large
                                onChange={(event) => this.handleUserInput(event)}
                                onFocus={() => this.onFocusChanged('car-model')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.car_make_model}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carModelInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Make & Model of the vehicle"
                              labelFor="carModel"
                              labelInfo="*"
                              helperText={this.state.carModelInvalid.length ? this.state.carModelInvalid : ''}
                            >
                              <InputGroup
                                name="car_make_model"
                                id="carModel"
                                large
                                onChange={(event) => this.handleUserInput(event)}
                                onFocus={() => this.onFocusChanged('car-model')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.car_make_model}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                      </div>
                      <div className="card-footer">
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            Mileage value:
                          </Box>
                          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                            Calculation Breakdown:<br/>
                            Mileage to date: {this.state.mileageToDate}<br/>
                            Mileage entered: {this.state.distanceTraveled}
                          </Box>
                        </Flex>
                      </div>
                    </Collapse>
                  </div>
                </Box>
              </Flex>
              {canEdit &&
              <Flex>
                <Box className="pt20 space-between" width={1 / 1}>
                  <Button large onClick={(event) => this.handleSubmit(event)} icon="add" intent="success"
                          text={this.state.recordId ? 'Save' : 'Add New'}/>
                  {this.state.recordId &&
                  <Button large onClick={(event) => this.setState({deleteDialog: true})} icon="trash" intent="danger"
                          text={'Delete'}/>}
                </Box>
              </Flex>
              }
            </div>
          </Scrollbar>
        </StyledExpensesForm>
      </React.Fragment>
    );
  }
}

export default TravelExpenseForm;
