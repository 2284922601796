import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {toggleAddressEditor, updateRecord} from "../../../../store/contractors/actions";
import {connect} from "react-redux";

class AddressEditor extends Component {
  render() {
    return (
      <div>

      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    record: state.contractors.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleAddressEditor,
    updateRecord,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(AddressEditor);
