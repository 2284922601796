import React, {Component} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import Select from 'react-select';
import { Flex, Box } from '@rebass/grid';
import Dropzone from 'react-dropzone'
import styled from 'styled-components';
import {FormGroup, InputGroup, Intent, Button, TextArea, Spinner, Dialog, Classes, Callout} from "@blueprintjs/core";
import {DateInput, TimePrecision} from "@blueprintjs/datetime";

import axios from 'axios';
import moment from "moment";
import BusinessExpenseUploadItem from "./BusinessExpenseUploadItem";
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {connect} from "react-redux";

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  min-height: 100%;
  
  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    
    .form-wrapper {
      padding: 10px;
    }
  }
  
  .card {
    margin-bottom: 10px;
    border-radius: 4px;
    
    .card-title {
      border-radius: 4px 4px 0 0;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #e4e5e9;
    }
    
    .card-body {
      padding: 10px 10px 0;
      border: 1px solid #e4e5e9;
    }
  }
  
  .status {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #edeef0;
    margin-bottom: 10px;
    padding: 5px 0;
    
    span {
      display: flex;
      align-items: center;
      &.value {
        font-weight: bold;
      }
    }
  }
`;

class BusinessExpenseForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      statusName: '',
      recordId: null,
      loading: true,
      serverOperation: null,
      categories: [],
      currencies: [],
      selectedCategory: null,
      selectedCurrency: null,
      isCategoryInvalid: '',
      isCurrencyInvalid: '',
      receipts: [],
      files: [],
      uploads: [],
      sent: false,
      statusId: null,
      deleteDialog: false,
      rejectDialog: false,
      rejection_reason: '',
      rejectionSent: false,
      eurValue: 0,
      fields: {
        value: '',
        description: '',
        incurred: null,
        currency: null,
        category: null,
        eurValue: 0,
      },
      errors: {},
    }
  }

  componentDidMount() {
    const { userId, expense } = this.props;
    const expenseId = expense && expense.id ? expense.id : 0;

    axios.get(`/contractors/${userId}/expenses/business/${expenseId}`)
      .then(result => {
        const { record, categories, currencies, files } = result.data;
        const formattedCategories = this.formatCategories(categories);
        const formattedCurrencies = this.formatCurrencies(currencies);
        const category = record && record.type_id ? record.type_id : '';
        const currency = record && record.currency_id ? record.currency_id : '';
        const selectedCategory = category ? formattedCategories.find(el => el.value === category) : null;
        const selectedCurrency = currency ? formattedCurrencies.find(el => el.value === currency) : null;
        const incurred = record && record.incurred && moment(record.incurred).toDate();
        const value = record && record.value ? record.value : 0;
        const eurValue = record && record.net ? record.net : 0;

        const uploads = files.map(file => {
          file.uploaded = true;
          return file;
        });

        this.setState({
          uploads,
          loading: false,
          recordId: record && record.id,
          statusName: record && record.status_name,
          statusId: record && record.status_id,
          rejection_reason: record && record.rejection_reason ? record.rejection_reason : '',
          eurValue,
          fields: {
            ...this.state.fields,
            category,
            currency,
            incurred: incurred,
            description: record && record.description ? record.description : '',
            value,
            eurValue,
          },
          receipts: files,
          selectedCategory,
          selectedCurrency,
          categories: formattedCategories,
          currencies: formattedCurrencies,
        });
      }).catch(error => {
        console.log(error);
    });
  }

  formatCategories(categories) {
    const formattedCategories = [];
    categories.map(category => {
      formattedCategories.push({label: category.name, value: category.id});
      return category;
    });
    return formattedCategories;
  }

  formatCurrencies(currencies) {
    const formattedCurrencies = [];
    currencies.map(currency => {
      formattedCurrencies.push({label: currency.name, value: currency.id, rate: currency.rate});
      return currency;
    });
    return formattedCurrencies;
  }

  onIncurredChanged(e) {
      const incurredDate = e;
      if ( !incurredDate ){
        this.setState({
          fields: { ...this.state.fields, incurred: incurredDate},
          errors: { ...this.state.errors, incurred: 'This Field is required' }
        });
      } else {
        this.setState({
          fields: { ...this.state.fields, incurred: incurredDate},
          errors: { ...this.state.errors, incurred: undefined }
        });
      }
  }

  onFocusChanged(fieldName) {
    if(this.props.onFocusChanged) {
      this.props.onFocusChanged(fieldName);
    }
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;

    let eurValue = this.state.fields.eurValue;
    if (name === 'value'){
      if (value && this.state.selectedCurrency && this.state.selectedCurrency.rate) {
        eurValue = parseFloat(parseFloat(value) / this.state.selectedCurrency.rate).toFixed(2);
      }
    }

    if (!value.length) {
      this.setState({
        fields: {...this.state.fields, eurValue, [name]: value},
        errors: {...this.state.errors, [name]: 'This Field is required.'}
      });
    } else {
      this.setState({
        fields: {...this.state.fields, eurValue, [name]: value},
        errors: {...this.state.errors, [name]: undefined}
      });
    }
  }

  handleRejectionInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      rejection_reason: value,
    }, () => {
      if (this.state.rejectionSent){
        this.validateRejection();
      }
    });
  }

  handleDrop(acceptedFiles){
    const { userId, expense } = this.props;
    const expenseId = expense && expense.id ? expense.id : 0;

    const uploads = acceptedFiles.map(file => {
      if (this.state.uploads.find(el => el.name === file.name)){
        return;
      }
      if (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg') {
        const formData = new FormData();
        if (this.state.recordId) {
          formData.append('expenseId', this.state.recordId);
        }
        formData.append("file", file);
        this.setState({
          uploads: [...this.state.uploads, file]
        },() => {
          return axios.post(`/contractors/${userId}/expenses/business/${expenseId}/receipt`, formData).then(response => {
            const originalName = response.data.originalName;
            const fileId = response.data.id;
            const fileName = response.data.name;

            this.setState({
              uploads: this.state.uploads.map(el => {
                if (el.name === originalName){
                  el.id = fileId;
                  el.originalName = fileName;
                  el.uploaded = true;
                }
                return el;
              })
            }, () => {
              console.log(this.state.uploads);
            });
          });
        });
      }
    });
  }

  deleteFile (id) {
    const { userId, expense } = this.props;
    const expenseId = expense && expense.id ? expense.id : 0;
    this.setState({
      uploads: this.state.uploads.map(upload => {
        if (upload.id === id){
          upload.uploaded = false;
        }
        return upload;
      }),
    })
    return axios.delete(`/contractors/${userId}/expenses/business/${expenseId}/receipt/${id}`).then(response => {
      this.setState({
        uploads: this.state.uploads.filter(upload => upload.id !== id),
      }, () => {
        this.props.toast({message: 'Receipt deleted', intent: 'success'});
      })
    }).catch(error => {

    });
  }

  validate() {
    let hasError = false;

    const errors = {
      incurred: null,
      category: null,
      description: null,
      currency: null,
      value: null,
      files: null,
      agree1: null,
      agree2: null,
    };

    if (moment(this.state.fields.incurred).isValid()){
      errors.incurred = null;
    } else {
      errors.incurred = 'This Field is required.';
      hasError = true;
    }

    if (this.state.fields.category){
      errors.category = null;
    } else {
      errors.category = 'This Field is required.';
      hasError = true;
    }

    if (this.state.fields.description.length){
      errors.description = null;
    } else {
      errors.description = 'This Field is required.';
      hasError = true;
    }

    if (!this.state.recordId || this.state.fields.value > 0) {
      if (this.state.fields.currency) {
        errors.currency = null;
      } else {
        errors.currency = 'This Field is required.';
        hasError = true;
      }

      if (this.state.fields.value) {
        errors.value = null;
      } else {
        errors.value = 'This Field is required.';
        hasError = true;
      }
    }

    this.setState({
      sent: true,
      errors,
    });

    return !hasError;
  }

  validateRejection() {
    let hasError = false;

    const errors = {
      rejection_reason: null,
    };

    if (this.state.rejection_reason.length){
      errors.rejection_reason = null;
    } else {
      errors.rejection_reason = 'This Field is required.';
      hasError = true;
    }

    this.setState({
      errors
    })

    return !hasError;
  }

  changeCategory(option) {
    this.setState({
      selectedCategory: option,
      fields: {
        ...this.state.fields,
        category: option.value,
      }
    }, () => {
      if (this.state.sent) {
        if (this.state.errors.category){
          this.setState({
            ...this.state,
            errors: {
              ...this.state.errors,
              category: undefined,
            }
          })
        }
      }
    });
  }

  changeCurrency(option) {
    const {value} = this.state.fields;
    let eurValue = 0;
    if (value && option.rate) {
      eurValue = parseInt(value) / option.rate;
    }

    this.setState({
      selectedCurrency: option,
      fields: {
        ...this.state.fields,
        eurValue: eurValue ? parseFloat(eurValue).toFixed(2) : 0,
        currency: option.value,
      }
    }, () => {
      if (this.state.sent) {
        if (this.state.errors.currency){
          this.setState({
            ...this.state,
            errors: {
              ...this.state.errors,
              currency: undefined,
            }
          })
        }
      }
    });
  }

  handleSubmit (event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      sent: true,
    }, () => {
      const { userId, expense } = this.props;
      if (this.validate()){
        const data = this.state.fields;
        data.files = this.state.uploads.map((el) => {
          return el.id
        });
        if (this.state.recordId) {
          axios.post(`/contractors/${userId}/expenses/business/${this.state.recordId}`, data).then(result => {
            this.props.onBusinessEdited();
            this.props.toast({message: 'Business expense as been successfully updated', intent: 'success'})
          }).catch(error => {
            console.log(error);
          });
        } else {
          axios.post(`/contractors/${userId}/expenses/business`, data).then(result => {
            this.props.onBusinessEdited();
            this.props.toast({message: 'Business expense as been successfully added', intent: 'success'})
          });
        }
      }
    });
  }

  handleDelete (event) {
    this.setState({deleteDialog: false,});
    const { userId } = this.props;
    axios.delete(`/contractors/${userId}/expenses/business/${this.state.recordId}`).then(result => {
      this.props.toast({message: 'Business expense as been successfully deleted', intent: 'success'});
      this.props.closeBusinessEditor();
      this.props.onBusinessEdited();
    }).catch(error => {
      console.log(error);
    });

  }

  viewFile (upload) {
    console.log(upload);
    const { userId } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/contractors/${userId}/expenses/business/${this.state.recordId}/receipt/${upload.internal}?auth=${window.localStorage.getItem('ammotio-token')}&session=${window.localStorage.getItem('ammotio-session')}`;
    window.open(url, "_blank");
  }

  setStatus(status) {
    this.setState({
      serverOperation: status,
    });

    const { userId, expense } = this.props;
    axios.put(`/contractors/${userId}/expenses/business/${expense.id}/${status}`,).then(result => {
      console.log(result.data.status);
      this.setState({
        serverOperation: null,
        statusId: parseInt(result.data.status),
        statusName: result.data.statusName,
      }, () => {
        this.props.onBusinessEdited(true);
        this.props.toast({message: 'Business expense status has been successfully updated', intent: 'success'})
      });
    })
  }

  handleReject() {
    this.setState({
      ...this.state,
      rejectionSent: true,
    },() => {
      if (this.validateRejection()){
        this.setState({
          serverOperation: 3,
        });

        const data = {
          reason: this.state.rejection_reason
        };

        const { userId, expense } = this.props;
        axios.put(`/contractors/${userId}/expenses/business/${expense.id}/3`, data).then(result => {
          console.log(result.data.status);
          this.setState({
            serverOperation: null,
            statusId: parseInt(result.data.status),
            statusName: result.data.statusName,
            rejectDialog: false,
          }, () => {
            this.props.onBusinessEdited(true);
            this.props.toast({message: 'Business expense status has been successfully updated', intent: 'success'})
          });
        })
      }
    });
  }

  render() {
    const {loading, statusName, statusId, serverOperation, recordId} = this.state;


    const value = this.state.fields.value && this.state.fields.value > 0 ? this.state.fields.value : null;

    //disable edit if value is 0 or null - as we dont have currency rate for old expenses
    //Also disable edit if the status is not 1
    let canEdit = (!recordId || parseInt(statusId) === 1) ? true : false;


    return (
      <React.Fragment>
        <StyledForm hideHeader={this.props.hideHeader} noValidate onSubmit={ event => this.handleSubmit(event) }>
          {loading ? <Spinner /> : <React.Fragment>
            <div className="content">
              <Scrollbar>
                <div className="form-wrapper">
                  {statusName &&
                  <Flex className="row">
                    <Box width={1/1}>
                      <div className="status">
                        <span>{statusName}</span>
                        <span className="value">
                          {statusId === 1 &&
                          <React.Fragment>
                            <Button loading={serverOperation === 'approving'} className="mr5" large intent="success"
                                    onClick={() => this.setStatus(2)}>Approve</Button>
                            <Button large intent="danger" onClick={() => this.setState({rejectDialog: true})}>Reject</Button>
                          </React.Fragment>
                          }
                          {statusId === 2 &&
                          <React.Fragment>
                            <Button loading={serverOperation === 5} className="mr5" large intent="success"
                                    onClick={() => this.setStatus(5)}>Pay</Button>
                            <Button loading={serverOperation === 1} large intent="danger" onClick={() => this.setStatus(1)}>Set as Submitted</Button>
                          </React.Fragment>
                          }
                        </span>
                      </div>
                    </Box>
                  </Flex>
                  }
                  <Flex className="row">
                    <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                      <FormGroup
                        style={{width: '100%'}}
                        intent={this.state.errors.incurred ? Intent.DANGER : Intent.SUCCESS}
                        label="Incurred"
                        labelFor="incurred"
                        labelInfo="*"
                        helperText={this.state.errors.incurred ? this.state.errors.incurred : ''}
                      >
                        <DateInput
                          disabled={!canEdit}
                          inputProps={{style: {width: '100%'}, className: 'bp3-large'}}
                          id="incurred"
                          formatDate={date => moment(date).locale('ie').format('DD/MM/YYYY HH:mm')}
                          parseDate={str => moment(str, 'DD/MM/YYYY HH:mm').locale('ie').toDate()}
                          onChange={(dt) => this.onIncurredChanged(dt)}
                          placeholder={"DD/MM/YYYY HH:MM"}
                          value={this.state.fields.incurred}
                          timePrecision={TimePrecision.MINUTE}
                          showActionsBar
                          intent={this.state.errors.incurred ? Intent.DANGER : Intent.NONE}
                        />
                      </FormGroup>
                    </Box>
                    <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                      <FormGroup
                        intent={this.state.errors.category ? Intent.DANGER : Intent.SUCCESS}
                        label="Category"
                        labelFor="category"
                        labelInfo="*"
                        helperText={this.state.errors.category ? this.state.errors.category : ''}
                      >
                        <Select
                          isDisabled={!canEdit}
                          id="category"
                          name="category"
                          multi={false}
                          options={this.state.categories}
                          onChange={option => this.changeCategory(option)}
                          value={this.state.selectedCategory}
                        />
                      </FormGroup>
                    </Box>
                  </Flex>
                  <Flex className="row">
                    <Box width={1/1}>
                      <FormGroup
                        intent={this.state.errors.description ? Intent.DANGER : Intent.SUCCESS}
                        label="Description"
                        labelFor="description"
                        labelInfo="*"
                        helperText={this.state.errors.description ? this.state.errors.description : ''}
                      >
                        <TextArea
                          disabled={!canEdit}
                          id="description"
                          name="description"
                          growVertically
                          large
                          fill
                          value={this.state.fields.description}
                          onChange={(e) => this.handleUserInput(e)}
                        />
                      </FormGroup>
                    </Box>
                  </Flex>
                  {(value > 0 || !recordId) &&
                    <Flex>
                      <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                        <FormGroup
                          className="mb5"
                          intent={this.state.errors.currency ? Intent.DANGER : Intent.SUCCESS}
                          label="Currency (of entered value)"
                          labelFor="currency"
                          labelInfo="*"
                          helperText={this.state.errors.currency ? this.state.errors.currency : ''}
                        >
                          <Select
                            isDisabled={!canEdit}
                            id="currency"
                            name="currency"
                            multi={false}
                            options={this.state.currencies}
                            onChange={option => this.changeCurrency(option)}
                            value={this.state.selectedCurrency}
                          />
                        </FormGroup>
                      </Box>
                      <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                        <FormGroup
                          className="mb5"
                          intent={this.state.errors.value ? Intent.DANGER : Intent.SUCCESS}
                          label="Value"
                          labelFor="value"
                          labelInfo="*"
                          helperText={this.state.errors.value ? this.state.errors.value : ''}
                        >
                          <InputGroup
                            disabled={!canEdit}
                            name="value"
                            id="value"
                            large
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.fields.value}
                          />
                        </FormGroup>
                      </Box>
                    </Flex>
                  }
                  <Callout className="mb20">
                    <Flex>
                      <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                        EUR Value
                      </Box>
                      <Box className="align-right" width={['100%','50%','50%']} pl={['5px', '5px']}>
                        <strong className="pl10 right">€{this.state.fields.eurValue}</strong>
                      </Box>
                    </Flex>
                  </Callout>
                  {(!recordId || statusId === 1 || this.state.uploads.length > 0) &&
                    <Flex>
                      <Box width={1 / 1}>
                        <FormGroup
                          intent={this.state.errors.files ? Intent.DANGER : Intent.SUCCESS}
                          label="Receipts"
                          helperText={this.state.errors.files ? this.state.errors.files : ''}
                        >
                          <Dropzone disabled={!canEdit} onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                              <div {...getRootProps()} className="dd-area" style={{marginLeft: -4, marginRight: -4, marginTop: 0}}>
                                <input {...getInputProps()} />
                                {canEdit &&
                                <React.Fragment>
                                  <p>click to select or drag 'n' drop copy of the receipt(s) here</p>
                                  <p>you can use jpg, png of pdf format</p>
                                </React.Fragment>
                                }
                                <table>
                                  <tbody>
                                  {this.state.uploads.map((upload, index) => (
                                    <BusinessExpenseUploadItem canDelete={!canEdit}
                                                               onViewClick={(upload) => this.viewFile(upload)}
                                                               onDeleteClick={(id) => this.deleteFile(id)} key={index}
                                                               upload={upload}/>
                                  ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </Dropzone>
                        </FormGroup>
                      </Box>
                    </Flex>
                  }
                  {canEdit &&
                    <Flex>
                      <Box className="pt20 space-between" width={1 / 1}>
                        <Button large onClick={(event) => this.handleSubmit(event)} icon="add" intent="success"
                                text={this.state.recordId ? 'Save' : 'Add New'}/>
                        {this.state.recordId &&
                        <Button large onClick={(event) => this.setState({deleteDialog: true})} icon="trash" intent="danger"
                                text={'Delete'}/>}
                      </Box>
                    </Flex>
                  }
                </div>
              </Scrollbar>
            </div>
          </React.Fragment>}
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="Delete Business Expense"
            isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </StyledForm>
        <form>
        <Dialog
          icon="info-sign"
          onClose={() => this.setState({rejectDialog: false})}
          title="Reject Business Expense"
          isOpen={this.state.rejectDialog}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              intent={this.state.errors.rejection_reason ? Intent.DANGER : Intent.SUCCESS}
              label="Describe your reason for rejection"
              labelFor="rejection_reason"
              labelInfo="*"
              helperText={this.state.errors.rejection_reason ? this.state.errors.rejection_reason : ''}
            >
              <TextArea
                id="rejection_reason"
                name="rejection_reason"
                growVertically
                large
                fill
                value={this.state.rejection_reason}
                onChange={(e) => this.handleRejectionInput(e)}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => this.setState({rejectDialog: false})}>Cancel</Button>
              <Button intent="DANGER" onClick={() => this.handleReject()}>Reject</Button>
            </div>
          </div>
        </Dialog>
        </form>
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}

export default (connect(null, matchDispatchToProps)(BusinessExpenseForm));
