import React, {Component} from 'react';
import Months from './Months';
import ExpensesList from "./ExpensesList";
import {connect} from "react-redux";
import SlidingPanel from "../../../../components/slidingpanel";
import BusinessExpenseForm from "./BusinessExpenseForm";
import TravelExpenseForm from "./TravelExpenseForm";
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getExpenses, getExpensesSummary} from "../../../../store/contractors/actions";

class Expenses extends Component {
  state = {
    expense: null,
    travelEditor: false,
    businessEditor: false,
  }

  componentDidMount() {
    let travelEditor = false;
    let businessEditor = false;

    if (this.props.match.params._expense_type) {
      if (this.props.match.params._expense_type === 'travel') {
        travelEditor = true;
      } else if (this.props.match.params._expense_type === 'business') {
        businessEditor = true;
      }
    }

    this.setState({
      travelEditor,
      businessEditor
    })
  }

  showExpense (expense) {
    if (expense.type === "business") {
      this.setState({
        expense: expense,
        businessEditor: true,
      });
    } else if (expense.type === "travel") {
      this.setState({
        expense: expense,
        travelEditor: true,
      });
    }
  }

  addExpense(type) {
    if (type === "business") {
      this.setState({
        businessEditor: true,
      });
    } else if (type === "travel") {
      this.setState({
        travelEditor: true,
      });
    }
  }

  onBusinessEdited(keepEditorOpen) {
    this.setState({
      expense: null,
      businessEditor: keepEditorOpen ? keepEditorOpen : false,
    }, () => {
      this.props.getExpensesSummary(this.props.match.params._id, r => {});
      this.props.getExpenses(this.props.match.params._id, this.props.match.params._year, this.props.match.params._month, r => {});
    });
  }

  render() {
    const { _id } = this.props.match.params;
    return (
      <React.Fragment>
          <div className="split">
            <div className="left">
              <Months addExpense={(type) => this.addExpense(type)} {...this.props} />
            </div>
            <div className="right">
              <ExpensesList showExpense={(expense) => this.showExpense(expense)} {...this.props}/>
            </div>
          </div>
          <SlidingPanel width="520px"
                        isOpen={this.state.businessEditor} title="Business Expense"
                        onRequestClose={() => this.setState({expense: null, businessEditor: false})}>
            <BusinessExpenseForm match={this.props.match} closeBusinessEditor={() => this.setState({expense: null, businessEditor: false})} onBusinessEdited={(status) => this.onBusinessEdited(status)} userId={_id} expense={this.state.expense} />
          </SlidingPanel>
          <SlidingPanel width="520px"
                        isOpen={this.state.travelEditor} title="Travel Expense"
                        onRequestClose={() => this.setState({expense: null, travelEditor: false})}>
            <TravelExpenseForm match={this.props.match} userId={_id} expense={this.state.expense} />
          </SlidingPanel>

      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getExpenses,
    getExpensesSummary,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    expensesSummaryLoading: state.contractors.expensesSummaryLoading,
    expensesLoading: state.contractors.expensesLoading,
    expenses: state.contractors.expenses,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Expenses));
