import axios from 'axios';
import { randomString } from '../../../utils/random';
import {userConstants} from '../contants';
export function loginUser(userObject){
  const { history } = this;

  return function(dispatch){
    let session = window.localStorage.getItem('ammotio-session');
    if(!session || session.length === 0){
      session = randomString(36);
      window.localStorage.setItem('ammotio-session', session);
    }
    userObject.session = session;
    dispatch({type:userConstants.LOGIN_REQUEST});
    axios.post('/auth/login', userObject)
      .then((result)=>{
        window.localStorage.setItem('ammotio-token', result.data.token);
        window.localStorage.setItem('ammotio-session', result.data.session);
        axios.defaults.headers.common['Authorization'] = result.data.token;
        axios.defaults.headers.common['Session'] = result.data.session;

        // connect to socket - middleware
        dispatch({type: 'SOCKET_CONNECT'});
        dispatch({type: "SET_COUNTRIES", payload: result.data.lists.countries});
        dispatch({type: "SET_GENDERS", payload: result.data.lists.gender});
        dispatch({type: "SET_AGENCIES", payload: result.data.lists.agencies});
        dispatch({type: "SET_PROFESSIONS", payload: result.data.lists.professions});
        dispatch({type: "SET_POSITIONS", payload: result.data.lists.positions});
        dispatch({type:userConstants.LOGIN_SUCCESS, user: result.data});

        history.push('/');
      })
      .catch((err)=>{
        dispatch({type:userConstants.LOGIN_FAILURE, error: err});
      })
  }
}

export function checkUser(){
  return function(dispatch){
    dispatch({type:userConstants.CHECK_REQUEST});

    axios.get('/auth/check').then(result => {
      // connect to socket - middleware
      dispatch({type: 'SOCKET_CONNECT'});
      dispatch({type: "SET_COUNTRIES", payload: result.data.lists.countries});
      dispatch({type: "SET_GENDERS", payload: result.data.lists.gender});
      dispatch({type: "SET_AGENCIES", payload: result.data.lists.agencies});
      dispatch({type: "SET_PROFESSIONS", payload: result.data.lists.professions});
      dispatch({type: "SET_POSITIONS", payload: result.data.lists.positions});
      dispatch({type:userConstants.CHECK_SUCCESS, user: result.data, modules: result.data.modules});
    }).catch(error => {
      dispatch({type:userConstants.CHECK_FAILURE, error: error});
    }).finally();

  }
}

export function logoutUser(){
  return function(dispatch){
    dispatch({type: userConstants.LOGOUT});
    axios.post('/auth/logout').then(result => {
      dispatch({type: 'DISCONNECT_SOCKET'});
      window.localStorage.removeItem("ammotio-token");
      axios.defaults.headers.common['Authorization'] = "";
    }).catch(error => {
      dispatch({type: 'DISCONNECT_SOCKET'});
      window.localStorage.removeItem("ammotio-token");
      axios.defaults.headers.common['Authorization'] = "";
    });
  }
}

export function setUser(userObject){
  console.log(userObject);
  return function(dispatch){
    dispatch({type:"LOGIN_USER", payload: userObject});
    dispatch({type:"LOGGING_IN_USER", payload: false});
  }
}


