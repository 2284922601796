import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import Scrollbar from 'react-scrollbars-custom';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Spinner} from '@blueprintjs/core';
import ExpenseItem from '../ExpenseItem';
import Filter from './Filter';
import {toast} from '../../../../../store/toaster/actions';
import {getExpenses} from '../../../../../store/contractors/actions';


const expensesFilterGroups = {
  type: ["business", "travel"],
  status: ["Approved", "Submitted", "Rejected", "Paid", "Deleted"]
};

class Index extends Component {

  state = {
    records: [],
    month: null,
    year: null,
    travelEditor: false,
    businessEditor: false,
    expense: null,
    filter: ["business", "travel", "Approved", "Submitted", "Rejected", "Paid", "Deleted"]
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      month: parseInt(this.props.match.params._month),
      year: parseInt(this.props.match.params._year),
      expenseType: parseInt(this.props.match.params._expense_type),
      expenseId: parseInt(this.props.match.params._expense_id),
    });

    this.props.getExpenses(this.props.match.params._id, this.props.match.params._year, this.props.match.params._month, r => {});
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.props.match.params._month !== prevProps.match.params._month) {
      this.props.getExpenses(this.props.match.params._id, this.props.match.params._year, this.props.match.params._month, r => {});
    }
  }

  filterExpenses = e =>
    Object.keys(expensesFilterGroups).reduce((valid, k) => {
      const useGroup = this.state.filter.some(v =>
        expensesFilterGroups[k].includes(v)
      );
      return valid && (!useGroup || this.state.filter.includes(e[k]));
    }, true);

  showExpense(expense) {
    const {_id, _year, _month} = this.props.match.params;
    this.props.history.push(`/contractors/${_id}/expenses/${_year}/${_month}/${expense.type}/${expense.id}`)
    this.props.showExpense(expense);
  }

  onFilterChanged(filter) {
    this.setState({
      ...this.state,
      filter
    })
  }

  render() {
    const { _year, _month } = this.props.match.params;
    const { expenses, expensesLoading } = this.props;

    let visible = false;
    if (_year && _month){
      visible = true;
    }
    return (
      <React.Fragment>
        {visible ?
          <React.Fragment>
            {expensesLoading ?
              <Spinner className="mt50" /> :
              <React.Fragment>
                <div className="tools">
                </div>
                <div className="tools">
                  <Filter onChange={(filters) => this.onFilterChanged(filters)} expenses={expenses} />
                </div>
                <Scrollbar noDefaultStyles>
                  <div className="expenses-list">
                    {expenses
                      .filter(this.filterExpenses)
                      .map(expense => (
                        <ExpenseItem onClick={(expense) => this.showExpense(expense)} key={expense.id} data={expense}/>
                      ))}
                  </div>
                </Scrollbar>
              </React.Fragment>}
          </React.Fragment> : <div></div> }

        </React.Fragment>
    )
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getExpenses
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    expensesLoading: state.contractors.expensesLoading,
    expenses: state.contractors.expenses,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(withRouter(Index)));
