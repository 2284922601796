import React, {Component} from 'react';
import ExpenseTypeFilter from './ExpenseTypeFilter';
import ExpenseStatusFilter from './ExpenseStatusFilter';
import styled from 'styled-components';

const StyledFilter = styled.div`
  display: flex;
  
`;

class Filter extends Component {
  state = {
    businessCount: 0,
    travelCount: 0,
    business: false,
    travel: false,
    filters: this.props.filters ? this.props.filters : [],
  }

  componentDidMount() {
    const businessCount = this.props.expenses.filter(item => { return item.type === 'business' }).length;
    const travelCount = this.props.expenses.filter(item => { return item.type === 'travel' }).length;
    this.setState({
      businessCount,
      travelCount,
    })
  }

  setFilter(type, status) {
    let { filters } = this.state;
    if (status) {
      filters.push(type)
    } else {
      filters = filters.filter( item => item !== type);
    }

    console.log(filters);

    this.setState({
      filters
    }, () => this.props.onChange(filters));
  }

  render() {
    const { businessCount, travelCount } = this.state;

    return (
      <StyledFilter>
        <ExpenseTypeFilter label="Business Expenses" onChange={(val) => this.setFilter('business', val)} total={businessCount} color="#6c85d1" />
        <ExpenseTypeFilter label="Travel Expenses" onChange={(val) => this.setFilter('travel', val)} total={travelCount} color="#63b7ee" />
        <ExpenseStatusFilter onChange={(val) => this.setFilter('Paid', val)} total={0} color="#39cfa3" />
        <ExpenseStatusFilter onChange={(val) => this.setFilter('Submitted', val)} total={0} color="#fca22e" />
        <ExpenseStatusFilter onChange={(val) => this.setFilter('Deleted', val)} total={0} color="#f8224d" />
      </StyledFilter>
    );
  }
}

export default Filter;
