import React, { Component } from 'react';
import Select from 'react-select';
import Scrollbar from 'react-scrollbars-custom';
import { Link } from 'react-router-dom';
import months from './MonthsArray';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getExpensesSummary} from "../../../../store/contractors/actions";
import {connect} from "react-redux";
import moment from "moment";
import {Button, PopoverInteractionKind, Popover, Position, Intent, Spinner} from "@blueprintjs/core";

class Months extends Component {
  state = {
    search: '',
    record: parseInt(this.props.match.params._id),
    records: null,
    years: [],
    selectedYear: null,
  }

  componentDidMount() {
   this.props.getExpensesSummary(this.props.match.params._id, r => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.expensesSummary);
    if (this.state.record !== parseInt(nextProps.match.params._id)){
      this.setState({
        record: parseInt(nextProps.match.params._id),
        //records: this.formatData(nextProps.expensesSummary),
      }, () => {
        this.props.getExpensesSummary(this.props.match.params._id, r => {});
      });
    } else {
      const records = nextProps.expensesSummary;
      const years = Object.keys(records);
      years.sort(function(a, b){return b - a});
      let selectedYear = null;
      const _year = nextProps.match.params._year
      if (_year){
        const propYear = years.indexOf(_year);
        if (propYear > -1){
          selectedYear = years[propYear];
        } else {
          selectedYear = years[0];
        }
      } else {
        selectedYear = years[0];
      }
      if (!selectedYear) {
        const currentYear = new Date().getFullYear();
        years.push(currentYear);
        selectedYear = currentYear;
      }
      const formatedYears = [];
      years.map(y => {
        formatedYears.push({label: y, value: y});
      });

      const selectedRecords = records[selectedYear];
      this.setState({
        records: selectedRecords,
        years: formatedYears,
        selectedYear: {label: selectedYear, value: selectedYear}
      });
    }
  }

  formatData(records){
    let formattedRecords = [];
    records.map(record => {
      const year = moment(record.submitted).isValid() ? parseInt(moment(record.submitted).format('YYYY')) : null;
      const month = moment(record.submitted).isValid() ? parseInt(moment(record.submitted).format('M')) : null;
      const formatted = {
        year: year,
        month: month,
        business: record.business,
        travel: record.travel,
        total: record.total,
      }
      return formattedRecords.push(formatted);
    });
    return formattedRecords;
  }

  handleChangeYear(year){
    const { _id } = this.props.match.params;
    this.setState({
      selectedYear: year,
    }, () => {
      return this.props.history.push(`/contractors/${_id}/expenses/${year.value}`);
    })
  }

  render() {
    const { _id , _year, _month } = this.props.match.params;
    const availableYear = this.state.years.length ? this.state.years[0].value : new Date().getFullYear();
    const selectedYear = _year ? parseInt(_year) : availableYear;
    const records = this.state.records;
    return (
      <React.Fragment>
        {this.props.expensesSummaryLoading ?
          <Spinner className="mt50" /> :
          <React.Fragment>
            <div className="tools">
              <div>
                <Popover
                  interactionKind={PopoverInteractionKind.CLICK}
                  popoverClassName="bp3-popover-content-sizing expenses-popover"
                  position={Position.BOTTOM_LEFT}
                >
                  <Button large intent={Intent.SUCCESS}>Add New</Button>
                  <div>
                    <div className=""><Button onClick={() => this.props.addExpense('business')} large className="business">Business Expense</Button></div>
                    <div className="mt5"><Button onClick={() => this.props.addExpense('travel')} large className="travel">Travel Expense</Button></div>
                  </div>
                </Popover>
              </div>
              <Select
                className="year-picker"
                options={this.state.years}
                onChange={option => this.handleChangeYear(option)}
                value={this.state.selectedYear}
              />
            </div>
            <Scrollbar noDefaultStyles>
              <div className="months">
                {[ ...Array(12).keys() ].map((m, month) => {
                  const record = records && records[m+1] ? records[m+1] : null;
                  return (
                  <Link to={`/contractors/${_id}/expenses/${selectedYear}/${month+1}`}
                        key={month}
                        className={`${parseInt(_month - 1) === month ? 'selected ' : ''}month`}
                        >
                      <div className="header">
                        <p className="title">
                          {months[month]} {selectedYear}
                        </p>
                      </div>
                      <div className="content">{record && record.total ? '€' + record.total : '€0.00'}</div>
                      <div className="footer">
                        <div>
                        <div className="approved">
                          <div className="bullet" />
                          <p className="number">{record && record.approved ? '€' + record.approved : '€0.00'}</p>
                        </div>
                        <div className="waiting">
                          <div className="bullet" />
                          <p className="number">{record && record.awaiting ? '€' + record.awaiting : '€0.00'}</p>
                        </div>
                        </div>
                        <div>
                        <div className="denied">
                          <div className="bullet" />
                          <p className="number">{record && record.rejected ? '€' + record.rejected : '€0.00'}</p>
                        </div>
                        <div className="denied">
                          <div className="bullet" />
                          <p className="number">{record && record.deleted ? '€' + record.deleted : '€0.00'}</p>
                        </div>
                        </div>
                      </div>

                  </Link>);
                })}
              </div>
            </Scrollbar>
          </React.Fragment>
          }
      </React.Fragment>


    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getExpensesSummary
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    expensesSummaryLoading: state.contractors.expensesSummaryLoading,
    expensesSummary: state.contractors.expensesSummary,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Months));
