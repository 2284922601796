
const initialState = {
  countries: [],
  genders: [],
  agencies: [],
  professions: [],
  positions: [],
}

export function general(state = initialState, action) {
  switch (action.type) {
    case "SET_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };
    case "SET_GENDERS":
      return {
        ...state,
        genders: action.payload,
      };
    case "SET_AGENCIES":
      return {
        ...state,
        agencies: action.payload,
      };
    case "SET_PROFESSIONS":
      return {
        ...state,
        professions: action.payload,
      };
    case "SET_POSITIONS":
      return {
        ...state,
        positions: action.payload,
      };
    default:
      return state
  }
}
