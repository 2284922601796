// allows you to write action creators that return a function instead of an action
import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from "redux";

//reducers
import { user } from './user/reducers';
import { toaster } from './toaster/reducers';
import layout from './layout/reducers';
import {socket} from './socket/reducers';


//accountant reducers
import {companies} from './companies/reducers';
import {contractors} from './contractors/reducers';
import {profile} from './profile/reducers';
import {general} from './general/reducers';
import {invoices} from '../pages/contractor/invoices/store/reducers';
import {workingHours} from '../pages/contractor/workingHours/store/reducers';
import { reportsExpenses } from '../pages/accountant/reports/expenses/store/reducers';


//contractor reducers
import { expenses } from '../pages/contractor/expenses/store/reducers';
import { payslips } from '../pages/contractor/documents/payslips/store/reducers';
import { bankStatement } from '../pages/contractor/bankStatements/store/reducers';
import { p60 } from '../pages/contractor/documents/p60/store/reducers';

//settings reducers
import { settingsAgencies } from '../pages/settings/agencies/store/reducers';


//import socketIOReduxMiddleware from "./socketIOReduxMiddleware";
import echoMiddleware from "./echoMiddleware";

//--- MIDDLEWARE
// add middleware inside this function
const middleware = applyMiddleware(
  thunk,
  //socketIOReduxMiddleware,
  echoMiddleware,
);

//combine reducers
const Reducers = combineReducers({
  user,
  general,
  toaster,
  layout,
  socket,
  companies,
  contractors,
  profile,
  invoices,
  workingHours,
  expenses,
  payslips,
  bankStatement,
  p60,
  settingsAgencies,
  reportsExpenses,
});

//--- STORE
// this is the store
const Store= createStore(
  Reducers, middleware);

//we now export the store
//which is configured now
export default Store;
