import React, {Component} from 'react';
import moment from 'moment';

class ExpenseItem extends Component {
  render() {
    const { data } = this.props;
    const incurred = moment(data.incurred).format('DD/MM/YYYY');

    return (
      <React.Fragment>
        <div className={`expense-item ${data.type && data.type === 'travel' ? 'travel' : 'business'}`}>
                <div className="details">
                  <div className="info">
                    <p className="type">
                      {data.type && data.type === "travel" ? 'Travel Expense' : 'Business Expense'} - {incurred}
                    </p>
                    <p className="date">{data.description}</p>
                  </div>
                  <div className="value">
                    <div className="status awaiting_approval">
                      <div className="icon sc-kpOJdX iOciBM" size="20" color="#fff">
                      </div>
                    </div>
                    <div>€{data.total}</div>
                  </div>
                </div>
                <div className="footer">
                  <div className="status">
                    Status:&nbsp;<b>{data.status}</b></div>
                  <div className="actions">
                    <button className="view" onClick={() => this.props.onClick(data)}>View</button>
                  </div>
                </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ExpenseItem;
