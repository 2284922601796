import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getInvoices} from "../../../../store/contractors/actions";
import {connect} from "react-redux";
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from "styled-components";
import { CSVLink } from "react-csv";

import 'react-virtualized/styles.css';
import {Button, InputGroup, NonIdealState, Spinner} from "@blueprintjs/core";
import Fuse from "fuse.js";
import Icon from "../../../../components/icon";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.1,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'date', weight: 0.4},
    {name: 'description', weight: 0.4},
    {name: 'net', weight: 0.2},
    {name: 'vat_rate', weight: 0.2},
    {name: 'vat_amount', weight: 0.2},
    {name: 'gross', weight: 0.2},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Date', key: 'date' },
  { label: 'Description', key: 'description' },
  { label: 'Net', key: 'net' },
  { label: 'Vat', key: 'vat_amount' },
  { label: 'Gross', key: 'gross' },
];

class Invoices extends Component {
  state = {
    search: '',
    records: [],
    record: parseInt(this.props.match.params._id)
  }

  componentDidMount() {
    this.props.getInvoices(this.props.match.params._id, r => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.record !== parseInt(nextProps.match.params._id)){
      this.setState({
        record: parseInt(nextProps.match.params._id),
      }, () => {
        this.props.getInvoices(this.props.match.params._id, r => {});
      });
    } else {
      if (nextProps.records.length){
        this.setState({
          records: this.formatData(nextProps.records),
        });
      } else {
        this.setState({
          records: [],
        });
      }
    }
  }

  handleFilter(phrase){
    let records = this.formatData(this.props.records);
    if (this.state.search.length) {
      const fuse = new Fuse(records, FUSE_OPTIONS);
      records = fuse.search(this.state.search);
    }
    this.setState({
      search: phrase,
      records
    });
  }


  formatData(records){
    let formattedRecords = [];
    records.map(record => {
      record = {
        ...record,
        net: record.net ? record.net.toFixed(2) : parseInt('0').toFixed(2),
        gross: record.gross ? record.gross.toFixed(2) : parseInt('0').toFixed(2),
        vat_rate: `${record.vat_rate}%`,
        vat_amount: record.vat_amount ? record.vat_amount.toFixed(2) : parseInt('0').toFixed(2),
        date: record.date && record.date,
      };
      return formattedRecords.push(record);
    });
    return formattedRecords;
  }

  viewFile (invoiceId) {
    const { _id } = this.props.match.params;
    const url = `${process.env.REACT_APP_API_URL}/contractors/${_id}/invoices/get-file/${invoiceId}?auth=${window.localStorage.getItem('ammotio-token')}&session=${window.localStorage.getItem('ammotio-session')}`;
    window.open(url, "_blank");
  }


  render() {
    const { loading } = this.props;
    const { records } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="tools">
              <div></div>
              <div className="flex">
                {records.length > 0 &&
                  <CSVLink
                    headers={EXCEL_HEADERS}
                    data={records}
                    filename={"invoices.csv"}
                    className="mr5"
                    target="_blank"
                  >
                    <Icon icon="csv" size={38}/>
                  </CSVLink>
                }
                <InputGroup
                  leftIcon="search"
                  onChange={(e) => {
                    this.handleFilter(e.currentTarget.value)
                  }}
                  large
                  placeholder="Search"
                />
              </div>
            </div>
            {records.length ? (
              <StyledList>
              <AutoSizer>
                {({width, height}) => (
                  <Table
                    ref="Table"
                    headerHeight={30}
                    noRowsRenderer={this._noRowsRenderer}
                    overscanRowCount={10}
                    rowClassName={this._rowClassName}
                    rowHeight={30}
                    rowCount={records.length}
                    rowGetter={({ index }) => records[index]}
                    height={height}
                    width={width}>
                    <Column
                      label="Date"
                      dataKey="date"
                      width={width * 0.2}
                    />
                    <Column
                      width={width * 0.2}
                      disableSort
                      label="Description"
                      dataKey="description"
                    />
                    <Column
                      width={width * 0.2}
                      disableSort
                      label="Net"
                      dataKey="net"
                    />
                    <Column
                      width={width * 0.2}
                      disableSort
                      label="VAT"
                      dataKey="vat_amount"
                    />
                    <Column
                      width={width * 0.2}
                      disableSort
                      label="GROSS"
                      dataKey="gross"
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      style={{textAlign: 'center'}}
                      label=""
                      dataKey="id"
                      cellRenderer={({cellData}) => (<Button onClick={() => {this.viewFile(cellData)}} small>View</Button>)}
                    />
                  </Table>
                )}
              </AutoSizer>
            </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No invoices found for this contractor"
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getInvoices
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.contractors.invoicesLoading,
    records: state.contractors.invoices,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Invoices));
