import React, {Component} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {withRouter} from 'react-router-dom';
import ExpenseItem from "./ExpenseItem";
import {bindActionCreators} from "redux";
import {toast} from "../../../store/toaster/actions";
import {getExpensesList, getExpenses} from "./store/actions";
import {connect} from "react-redux";
import {Spinner} from "@blueprintjs/core";
import SlidingPanel from "../../../components/slidingpanel";
import BusinessExpenseForm from "./BusinessExpenseEditor/BusinessExpenseForm";
import TravelExpenseForm from "./TravelExpenseEditor/TravelExpenseForm";

class ExpensesList extends Component {
  state = {
    records: [],
    month: null,
    year: null,
    travelEditor: false,
    businessEditor: false,
    expense: null,
  }

  componentDidMount() {
    const { _month, _year } = this.props.match.params;
    if (_month && _year) {
      this.setState({
        month: parseInt(_month),
        year: parseInt(_year),
        records: [],
      });
      this.props.getExpensesList(this.props.match.params._year, this.props.match.params._month);
    }
  }

  componentDidUpdate(prevProps, nextProps) {
    const { _month, _year } = this.props.match.params;
    if (_month && _year) {
      if (_month !== prevProps.match.params._month) {
        this.props.getExpensesList(this.props.match.params._year, this.props.match.params._month);
      }
    }
  }

  showExpense(expense) {
    if(expense.type === 'business') {
      this.setState({
        expense,
        businessEditor: true,
      });
    } else {
      this.setState({
        expense,
        travelEditor:true,
      });
    }
  }

  onBusinessEdited (keepEditorOpen) {
    this.setState({
      expense: null,
      businessEditor: keepEditorOpen ? keepEditorOpen : false,
    }, () => {
      this.props.getExpenses();
      this.props.getExpensesList(this.props.match.params._year, this.props.match.params._month);
    });
  }

  onTravelEdited (keepEditorOpen) {
    this.setState({
      expense: null,
      travelEditor: keepEditorOpen ? keepEditorOpen : false,
    }, () => {
      this.props.getExpenses();
      this.props.getExpensesList(this.props.match.params._year, this.props.match.params._month);
    });
  }

  render() {
    const { _year, _month } = this.props.match.params;
    const { expenses, expensesLoading } = this.props;
    const { expense } = this.state;

    let visible = false;
    if (_year && _month){
      visible = true;
    }
    return (
      <React.Fragment>
        {visible ?
          <React.Fragment>
            {expensesLoading ?
              <Spinner className="mt50" /> :
              <React.Fragment>
                <div className="tools">
                </div>
                <Scrollbar noDefaultStyles>
                  <div className="expenses-list">
                    {expenses.map(expense => (
                      <ExpenseItem onClick={(expense) => this.showExpense(expense)} key={expense.id} data={expense}/>
                    ))}
                  </div>
                </Scrollbar>
              </React.Fragment>}
            <SlidingPanel width="520px"
                          isOpen={this.state.businessEditor} title="Business Expense"
                          onRequestClose={() => this.setState({expense: null, businessEditor: false})}>
              <BusinessExpenseForm onBusinessEdited={() => this.onBusinessEdited()} hideHeader expense={expense} />
            </SlidingPanel>
            <SlidingPanel width="520px"
                          isOpen={this.state.travelEditor} title="Travel Expense"
                          onRequestClose={() => this.setState({expense: null, travelEditor: false})}>
              <TravelExpenseForm onTravelEdited={() => this.onTravelEdited()} hideHeader expense={expense} />
            </SlidingPanel>

          </React.Fragment> : <div></div> }
        </React.Fragment>
    )
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getExpensesList,
    getExpenses
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    expensesLoading: state.expenses.expensesLoading,
    expenses: state.expenses.expenses,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(withRouter(ExpensesList)));
