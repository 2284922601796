import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getEmployees} from "../../../../store/companies/actions";
import {connect} from "react-redux";
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from "styled-components";
import Fuse from "fuse.js";

import 'react-virtualized/styles.css';
import {Spinner, NonIdealState, InputGroup} from "@blueprintjs/core";
import {CSVLink} from "react-csv";
import Icon from "../../../../components/icon";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'first_name', weight: 0.4},
    {name: 'last_name', weight: 0.2},
    {name: 'pps_number', weight: 0.2},
    {name: 'crew_code', weight: 0.2},
    {name: 'date_of_appointment', weight: 0.2},
    {name: 'date_of_resignation', weight: 0.2},
    {name: 'share_holding', weight: 0.2},
  ]
};

const EXCEL_HEADERS = [
  { label: 'First Name', key: 'first_name' },
  { label: 'Last Name', key: 'last_name' },
  { label: 'PPS Number', key: 'pps_number' },
  { label: 'Crew Code', key: 'crew_code' },
  { label: 'Date of appointment', key: 'date_of_appointment' },
  { label: 'Date of resignation', key: 'date_of_resignation' },
  { label: 'Shareholding', key: 'share_holding' },
];


class Employees extends Component {

  state = {
    search: '',
    record: parseInt(this.props.match.params._id)
  }

  componentDidMount() {
    this.props.getEmployees(this.props.match.params._id, r => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.record !== parseInt(nextProps.match.params._id)){
      this.setState({
        record: parseInt(nextProps.match.params._id)
      }, () => {
        this.props.getEmployees(this.props.match.params._id, r => {});
      });
    }
  }

  handleRowClick(data) {
    this.props.history.push(`/contractors/${data.id}`);
  }

  render() {
    const { employees, employeesLoading } = this.props;

    const fuse = new Fuse(employees, FUSE_OPTIONS);
    const records = this.state.search.length ? fuse.search(this.state.search) : employees;

    return (
      <React.Fragment>
      {employeesLoading ? (
        <Spinner className="mt50" />
      ) : (
        <React.Fragment>
          <div className="tools">
            <div></div>
            <div className="flex">
              {records.length > 0 &&
              <CSVLink
                headers={EXCEL_HEADERS}
                data={records}
                filename={"employees.csv"}
                className="mr5"
                target="_blank"
              >
                <Icon icon="csv" size={38}/>
              </CSVLink>
              }
              <InputGroup
                leftIcon="search"
                onChange={(e) => {
                  this.setState({
                    search: e.currentTarget.value
                  })
                }}
                large
                placeholder="Search"
              />
            </div>
          </div>
          {employees.length > 0 ? (
            <StyledList>
          <AutoSizer>
            {({width, height}) => (
              <Table
                ref="Table"
                headerHeight={30}
                noRowsRenderer={this._noRowsRenderer}
                overscanRowCount={10}
                rowClassName={this._rowClassName}
                rowHeight={30}
                rowCount={records.length}
                rowGetter={({ index }) => records[index]}
                height={height}
                onRowClick={(event) => this.handleRowClick(event.rowData)}
                width={width}>
                <Column
                  label='First Name'
                  dataKey='first_name'
                  width={width * 0.2}
                />
                <Column
                  label='Last Name'
                  dataKey='last_name'
                  width={width * 0.2}
                />
                <Column
                  label='PPS Number'
                  dataKey='pps_number'
                  width={width * 0.2}
                />
                <Column
                  label='Crew Code'
                  dataKey='crew_code'
                  width={width * 0.2}
                />
                <Column
                  label='Date Of Appointment'
                  dataKey='date_of_appointment'
                  width={width * 0.2}
                />
                <Column
                  label='Date Of Resignation'
                  dataKey='date_of_resignation'
                  width={width * 0.2}
                />
                <Column
                  label='Share Holding'
                  dataKey='share_holdeing'
                  width={width * 0.2}
                />
              </Table>
            )}
          </AutoSizer>
        </StyledList>
          ) : (
            <NonIdealState
              icon="search"
              title="No employees found for this company"
            />
          )}
        </React.Fragment>
      )}
      </React.Fragment>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getEmployees
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    employeesLoading: state.companies.employeesLoading,
    employees: state.companies.employees,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Employees));
