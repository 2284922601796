import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { FocusStyleManager } from "@blueprintjs/core";

import Store from "./store";
import {Provider} from "react-redux";
import styled from 'styled-components';

//import the routes
import Routes from "./routes";

import './styles/main.scss';

//sets axios defailt
import './api';

import Toaster from "./components/toaster";
import Header from "./components/header";
import Menu from "./components/menu";

const theme = {
  space: [ 0, 6, 12, 18, 24 ],
  breakpoints: [ '450px', '768px', '991px', '1400px', '1600px']
}

const StyledContent = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

FocusStyleManager.onlyShowFocusOnTabs();

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={Store}>
            <Toaster/>
            <Header />
            <StyledContent>
              <Menu/>
              <main>
                <Routes />
              </main>
            </StyledContent>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
