import React, {Component} from 'react';
import { Button, Callout, Intent } from '@blueprintjs/core';
import Dropzone from 'react-dropzone'
import axios from 'axios'
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {connect} from "react-redux";
import styled from 'styled-components';

const StyledReport = styled.div`
  text-align: left;
  margin: 10px;
  
  div {
    font-size: 12px;
    margin: 5px 0;
  }
  
  p {
    font-weight: 700;
    margin: 0;
  }
  
  .bp3-callout .bp3-heading {
    font-size: 14px;
    margin: 0;
  }
`;



class BankStatementsUploader extends Component {

  constructor(props){
    super(props);
    this.state = {
      files: [],
      uploading: false,
      errors: [],
      success: [],
    }
  }


  handleUpload = () => {
    if (this.state.files.length <= 0){
      this.props.toast({message: "Select at least one file.", intent: "danger"});
      return;
    }

    this.setState({
      uploading: true,
    }, () => {

      const errors = [];
      const success = [];
      const uploads = this.state.files.map(file => {
        const formData = new FormData();
        formData.append("file", file);

        return axios.post("/uploads/bank-statements", formData)
          .then(response => {
            success.push(response.data);
        }).catch(error => {
            errors.push(error.response.data);
        });
      });

      // Once all the files are uploaded
      axios.all(uploads).then(() => {
        //console.log('files uploaded');
        this.setState({
          files: [],
          errors,
          success,
          uploading: false,
        }, () => {
          this.props.toast({message: "Successfully uploaded.", intent: "success"});
        });
      });
    });
  }

  handleDrop(acceptedFiles){
    this.setState({files: acceptedFiles});
  }

  render() {
    const { success, errors, uploading } = this.state;
    return (
      <div className="center">
        <Dropzone onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()} className="dd-area">
                <input {...getInputProps()} />
                {this.state.files.length ? <div>{this.state.files.length} files selected<div><Button intent="danger" text="Remove" onClick={(e) => {e.stopPropagation(); this.setState({files: []})}} icon="delete"/></div></div> : <p>Drag 'n' drop *.out files here, or click to select files</p>}
              </div>
            </section>
          )}
        </Dropzone>
        <Button large="true" icon="cloud-upload" loading={uploading} onClick={() => this.handleUpload()} text="Upload" intent="success"/>
        <StyledReport>
          {errors.map((error, index) => (
            <Callout key={index} title={error.file} intent={Intent.DANGER}>
              <p>Account: {error.account} - Sort code: {error.sortcode}</p>
              {error.error}
              {error.code && error.code === 2 &&
                <ul>
                  {error.users && error.users.map(user => (
                    <li>{user.first_name} {user.last_name}</li>
                  ))}
                </ul>
              }
            </Callout>
          ))}
          {success.map((file, index) => (
            <Callout key={index} title={file.file} intent={Intent.SUCCESS}>
              <p>Account: {file.account} - Sort code: {file.sortcode}</p>
              Processed successfully
            </Callout>
          ))}
        </StyledReport>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}

export default (connect(null, matchDispatchToProps)(BankStatementsUploader));

