import React, {Component} from 'react';
import {Spinner, Button, Dialog, Classes} from '@blueprintjs/core';

class BusinessExpenseUploadItem extends Component {

  state = {
    deleteDialog: false,
  }

  confirmDelete() {


    //this.props.onDeleteClick(this.props.upload.id)}
  }



  render() {
    return (
      <React.Fragment>
        <tr onClick={(event) => event.stopPropagation()}>
          <td><Button icon='eye' minimal onClick={() => this.props.onViewClick(this.props.upload)}></Button></td>
          <td>{this.props.upload.name}</td>
          <td className="r">
            {this.props.upload.uploaded ? <React.Fragment>{!this.props.canDelete && <Button onClick={(event) => {event.preventDefault(); this.setState({deleteDialog: true})}} icon='trash' minimal></Button>}</React.Fragment> : <Spinner size={16} /> }
          </td>
        </tr>
        <Dialog
          icon="info-sign"
          onClose={() => this.setState({deleteDialog: false})}
          title="Delete Expense Receipts"
          enforceFocus={true}
          lazy={true}
          isOpen={this.state.deleteDialog}>
          <div className={Classes.DIALOG_BODY}>
            <div>Are you sure?</div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
              <Button intent="DANGER" onClick={() => this.props.onDeleteClick(this.props.upload.id)}>Delete</Button>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default BusinessExpenseUploadItem;
