import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import Select from "react-select";
import {formattedMonths} from '../../../../utils/months';
import {toast} from "../../../../store/toaster/actions";
import {getSummary} from "./store/actions";
import {connect} from "react-redux";
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from "styled-components";
import { CSVLink } from "react-csv";

import 'react-virtualized/styles.css';
import {NonIdealState, Spinner} from "@blueprintjs/core";
import Fuse from "fuse.js";
import Icon from "../../../../components/icon";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const StyledTools = styled.div`
  .filter-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    
    .months-select {
      width: 150px;
      margin-right: 10px;
    }   
    
    .years-select {
      width: 150px;
    }
    
  }
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'date', weight: 0.4},
    {name: 'details', weight: 0.2},
    {name: 'credit', weight: 0.2},
    {name: 'debit', weight: 0.2},
    {name: 'balance', weight: 0.2},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Date', key: 'date' },
  { label: 'Details', key: 'details' },
  { label: 'Debit', key: 'debit' },
  { label: 'Credit', key: 'credit' },
  { label: 'Balance', key: 'balance' },
];

class Expenses extends Component {

  state = {
    search: '',
    record: parseInt(this.props.match.params._id)
  }

  componentDidMount() {
    this.props.getSummary();
  }

  /*
  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.record !== parseInt(nextProps.match.params._id)){
      this.setState({
        record: parseInt(nextProps.match.params._id),
        records: nextProps.bankStatements,
      }, () => {
        this.props.getBankStatements(this.props.match.params._id, r => {});
      });
    }
  }
   */

  render() {
    const { records, loading } = this.props;
    const fuse = new Fuse(records, FUSE_OPTIONS);
    const allRecords = this.state.search.length ? fuse.search(this.state.search) : records;
    console.log(formattedMonths())

    return (
      <React.Fragment>
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <StyledTools className="tools">
              <div className="filter-wrapper">
                <Select
                  className="months-select"
                  options={formattedMonths()}
                  placeholder="All Professions"
                />
                <Select
                  className="months-select"
                  options={formattedMonths()}
                  placeholder="Month"
                />
                <Select
                  className="years-select"
                  options={formattedMonths()}
                  placeholder="Year"
                />
              </div>
              <div>
                {allRecords.length > 0 &&
                  <CSVLink
                    headers={EXCEL_HEADERS}
                    data={records}
                    filename={"reports_expenses.csv"}
                    className="mr5"
                    target="_blank"
                  >
                    <Icon icon="csv" size={38}/>
                  </CSVLink>
                }
              </div>
            </StyledTools>
            {allRecords.length ? (
              <StyledList>
                <AutoSizer>
                  {({width, height}) => (
                    <Table
                      ref="Table"
                      headerHeight={30}
                      noRowsRenderer={this._noRowsRenderer}
                      overscanRowCount={10}
                      rowClassName={this._rowClassName}
                      rowHeight={30}
                      rowCount={allRecords.length}
                      rowGetter={({ index }) => allRecords[index]}
                      height={height}
                      width={width}>
                      <Column
                        width={width * 0.2}
                        label="Contractor"
                        dataKey="user_name"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.2}
                        disableSort
                        label="Company"
                        dataKey="company_name"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Business Expenses"
                        dataKey="business"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Travel Expenses"
                        dataKey="travel"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Total"
                        dataKey="total"
                        flexGrow={1}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No expenses found"
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getSummary
  }, dispatch);
}

function mapStateToProps(state) {
  console.log(state);

  return {
    loading: state.reportsExpenses.loading,
    records: state.reportsExpenses.records,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Expenses));
